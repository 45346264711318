<template>
  <div
    v-if="
      (Array.isArray(passBlock) && passBlock.length) ||
      isUserUnavailable ||
      (Array.isArray(unavailable) && unavailable.length) ||
      showExceededIncidentsBanner ||
      studentIdCardsLink
    "
    class="container mt-4"
  >
    <div class="row">
      <div class="col-md-12">
        <div class="d-flex flex-column justify-conten-center position-relative">
          <div
            class="v3-dashboard-banners"
            :class="{ 'remove-bottom-radius': removeBorder }"
          >
            <PassBlockBanner
              v-if="passBlock"
              :pass-block="passBlock[0]"
              :active="show"
            />

            <OutOfOfficeBanner
              v-if="unavailable"
              :unavailable="unavailable[0]"
              :user-unavailable="isUserUnavailable"
              :active="show"
            />

            <ConsequencesBanner
              :exceeded-incidents="showExceededIncidentsBanner"
              :active="show"
            />

            <DownloadStudentIDCardsBanner
              :student-id-cards-link="studentIdCardsLink"
              :active="show"
            />
          </div>
          <div
            v-if="removeBorder"
            class="v3-dashboard-banners remove-top-radius"
            :class="{ 'more-than-four': moreThanFour }"
          >
            <div
              v-for="(item, index) in passBlockSlice"
              :key="index"
              class="w-100"
            >
              <PassBlockBanner
                :class="{ 'banner-border': shouldHaveBorder(index) }"
                :pass-block="item"
                :active="show"
              />
            </div>
            <div
              v-for="(item, index) in unavailableSlice"
              :key="index"
              class="w-100"
            >
              <OutOfOfficeBanner
                :class="{ 'banner-border': shouldHaveBorder(index) }"
                :unavailable="item"
                :user-unavailable="isUserUnavailable"
                :active="show"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <RowToggler :active="show" @click="toggleSidebar()" />
  </div>
</template>

<script>
import { computed } from "vue"
import { useStore } from "vuex"
import PassBlockBanner from "@/v3components/Dashboard/PassBlockBanner"
import OutOfOfficeBanner from "@/v3components/Dashboard/OutOfOfficeBanner"
import ConsequencesBanner from "@/v3components/Dashboard/ConsequencesBanner"
import DownloadStudentIDCardsBanner from "@/v3components/Dashboard/DownloadStudentIDCardsBanner"
import RowToggler from "@/v3components/Dashboard/RowToggler"

export default {
  name: "Banners",
  components: {
    PassBlockBanner,
    OutOfOfficeBanner,
    ConsequencesBanner,
    DownloadStudentIDCardsBanner,
    RowToggler
  },
  setup() {
    const store = useStore()

    const passBlock = computed(() => {
      return store.getters["dashboardTable/passBlock"]
    })

    const unavailable = computed(() => {
      return store.getters["dashboardTable/unavailable"]
    })

    const isUserUnavailable = computed(() => {
      return store.getters["authentication/isUserUnavailable"]
    })

    const exceededIncidents = computed(() => {
      return store.getters["exceededIncidents/exceededIncidents"]
    })

    const userRole = computed(() => {
      return store.getters["authentication/getUserRole"]
    })

    const showExceededIncidentsBanner = computed(() => {
      return exceededIncidents.value && userRole.value === "admin"
    })

    const studentIdCardsLink = computed(() => {
      return store.getters["users/studentIdCardsLink"]
    })

    const show = computed(() => {
      return store.state.layout.bannersShow
    })

    const toggleSidebar = () => {
      store.commit("layout/set", ["bannersShow", !show.value])
    }

    const shouldHaveBorder = (index) => {
      return index !== 0
    }

    const removeBorder = computed(() => {
      return (
        (show.value &&
          Array.isArray(passBlock.value) &&
          passBlock.value.length > 1) ||
        (show.value &&
          Array.isArray(unavailable.value) &&
          unavailable.value.length > 1)
      )
    })

    const passBlockSlice = computed(() => {
      return Array.isArray(passBlock.value) && passBlock.value.length > 1
        ? passBlock.value.slice(1)
        : []
    })

    const unavailableSlice = computed(() => {
      return Array.isArray(unavailable.value) && unavailable.value.length > 1
        ? unavailable.value.slice(1)
        : []
    })

    const moreThanFour = computed(() => {
      return passBlockSlice.value.length + unavailableSlice.value.length > 4
    })

    return {
      passBlock,
      unavailable,
      isUserUnavailable,
      showExceededIncidentsBanner,
      studentIdCardsLink,
      show,
      toggleSidebar,
      shouldHaveBorder,
      removeBorder,
      passBlockSlice,
      unavailableSlice,
      moreThanFour
    }
  }
}
</script>
